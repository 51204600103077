@import "../../variables";


.address-footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;

  .text {
    a {
      color: white;
    }

    a:hover {
      color: $primary-dark;
    }
  }

  .iconLine {
    display: flex;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 4px;
    margin-left: -26px;
  }

  .desktop {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .mobile {
    .iconLine {
      margin-left: 25px;
    }
  }

}

@media (min-width: $lg) {
  .address-footer-container {
    .desktop {
      .iconLine {
        margin-left: 40px;
      }
    }

    .mobile {
      display: none;
    }
  }
}

@media (min-width: $md) and (max-width: $lg) {
  .address-footer-container {
    .desktop {
      .iconLine {
        margin-left: 40px;
      }
    }

    .mobile {
      display: none;
    }
  }
}

@media (min-width: $sm) and (max-width: $md) {
  .address-footer-container {
    .iconLine {
      margin-left: 10px;
    }

    svg {
      display: none;
    }

    .mobile {
      display: none;
    }
  }
}

@media (max-width: $sm) {
  .address-footer-container {
    .iconLine {
      margin-left: 10px;
    }

    .desktop {
      display: none;
    }
  }
}
