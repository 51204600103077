@import "../../variables";

.middle-footer-container {
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    color: white;
  }

  a:hover {
    color: $primary-dark;
  }
}

@media (max-width: $md) {
  .middle-footer-container {
    color: white;
    margin-bottom: 20px;
  }
}

@media (min-width: $sm) {
  .middle-footer-container {
    color: white;
  }
}





