@import "../../variables";

.shopping-cart-icon {
  margin-top: 6px;
  margin-right: 30px;
  margin-left: 40px;

  svg {
    font-size: 30px;
    fill: $secondary-900;
  }

  .item-count {
    position: absolute;
    font-weight: bold;
    top: 42px;
    right: 0;
    padding: 0 6px;
    background-color: $secondary-900;
    border: 3px solid $secondary-900;
    color: white;
    border-radius: 20px;
  }
}

@media (max-width: $md) {
  .shopping-cart-icon {
    position: absolute;
    left: 0;
    margin: 0;

    svg {
      font-size: 25px;
    }

    .item-count {
      top: 3px;
      left: 23px;
      background-color: white;
      content: " ";
      border: 0;
      padding: 0;

      span {
        display: block;
        font-size: .7em !important;
        padding: 0 11px 2px 4px;
        background-color: $secondary-900;
        border: 3px solid $secondary-900;
        border-radius: 20px;
      }
    }
  }
}