@import '../../variables';

#coursePage {
  min-height: calc(100vh - 384px);

  #course-filter-area {
    h3 {
      margin-top: 16px;
      padding-left: 16px;
    }

    .filter-form {
      width: 100%;
    }

    .course-search-grid {
      margin-top: 0;
    }

    #filter-area-border {
      margin-top: 30px;
      margin-bottom: 38px;
      border-bottom: 3px solid $primary-main;
    }
  }
}