.recommendation-container {
  margin-top: 30px;

  .media-card {
    object-fit: contain;
    margin: 40px 0 10px;
  }

  .course-text p {
    color: black;
    font-size: .9rem !important;
    height: 100px;
    margin: 10px;
    text-align: center;
  }
}