@import "../../variables";

.contact-button {
  position: fixed;
  bottom: 30px;
  right: 30px;

  svg {
    display: block;
    font-size: 80px;
    padding: 20px;
    margin: 20px;
    fill: white;
    background-color: $secondary-dark;
    border-radius: 80px;
  }

  @media (max-width: $sm) {
    svg {
      margin: 10px;
    }
  }

  :nth-child(2) {
    background-color: $primary-dark;
    margin-top: 0;
  }

  a:nth-child(3) {
    margin-bottom: 0;
  }

  svg:hover {
    background-color: $primary-dark !important;
    cursor: pointer;
  }

  #qrcode-background {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba($primary-dark, .7);
    width: 100vw;
    height: 100vh;

    h3, h5 {
      color: white;
      background: none;
      text-align: center;
    }

    #qrcode {
      position: fixed;
      z-index: 99999;
      display: block;

      img {
        display: block;
        width: 400px;
        height: 400px;
        text-align: center;
      }
    }

    @media (max-width: $md) {
      #qrcode {
        top: 10vh;
        left: calc(50% - 150px);
        width: 300px;

        img {
          width: 300px;
          height: 300px;
        }
      }
    }

    @media (min-width: $md) {
      #qrcode {
        top: calc(50% - 275px);
        left: calc(50% - 175px);
        width: 350px;

        img {
          width: 350px;
          height: 350px;
        }
      }
    }

    @media (min-width: $lg) {
      #qrcode {
        position: fixed;
        display: block;
        top: calc(50% - 300px);
        left: calc(50% - 200px);
        width: 400px;

        img {
          width: 400px;
          height: 400px;
        }
      }
    }


  }
}