@import "../../variables";

.logo-bar-container {
  img {
    max-width: 200px;
    height: auto;
  }
}


.logo-bar-container {
  margin-bottom: 20px;
}

@media (max-width: $md) {
  .logo-bar-container {
    margin-bottom: 5px;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-content: center;
}