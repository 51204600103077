@import "../../variables";

@media (min-width: $md) {
  .upper-footer {
    display: flex;
    padding: 0 20%;
    width: 100%;
    background-color: $secondary-300;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .lower-footer {
    justify-content: space-between;
    padding: 2% 15%;
    display: flex;
    min-height: 20vh;
    width: 100%;
    background-color: $secondary-400;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: $md) {
  .upper-footer {
    display: flex;
    padding: 20px;
    width: 100%;
    flex-wrap: wrap;
    background-color: $secondary-300;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .lower-footer {
    justify-content: space-between;
    padding: 0;
    display: flex;
    min-height: 20vh;
    width: 100%;
    background-color: $secondary-400;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: $xs) {
  .upper-footer {
    padding: 20px;
  }
}




