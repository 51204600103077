@import '../../variables';

#checkoutPage {
  min-height: calc(100vh - 384px);

  .cart-card-area {
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid $primary-main;
    margin-top: 30px;
    margin-bottom: 38px;
  }
}

