@import "../../../variables";

#landing-page-top-section {

  @media (min-width: $md) {
    .testimonial {
      grid-area: testimonial;
      display: flex;
      flex-direction: column;
      min-height: 80%;
    }

    .bounceIcon {
      animation-iteration-count: infinite;
      animation-duration: 2s;
      display: flex;
      justify-content: center;
      margin: 0 auto 0 auto;
      transform-origin: bottom;
      background-image: url('../../../assets/images/scroll_icon.svg');
      width: 50px;
      height: 70px;
      background-repeat: no-repeat;
    }

    .buttonArea {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      grid-area: button;
      width: 100%;
      height: 100%;
    }

    .button {
      grid-area: button;
      border-width: 2px;
      margin: 0 0 20% 0;
    }

    .gridSection {
      grid-template-areas:  "testimonial testimonial button"
                            ". . button"
                            "arrow arrow arrow";
      grid-auto-columns: minmax(300px, auto);
      background-image: url('../../../assets/images/compucollege-1.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: calc(100vh - 140px);
      padding: 2% 5%;
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 20%;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: $md) {
    .testimonial {
      align-self: end;
      justify-self: center;
      grid-area: testimonial;
      min-width: 100%;
    }

    .buttonArea {
      justify-self: center;
      align-self: end;
      grid-area: button;
      width: 100%;
    }

    .button {
      grid-area: button;
      border-width: 2px;
      align-self: center;
      min-width: 100%;
      margin-right: 0;
    }

    .bounceIcon {
      display: none;
    }
    .gridSection {
      grid-template-areas:  "testimonial"
                            "button";
      background-image: url('../../../assets/images/compucollege-1.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: calc(100vh - 140px);
      padding: 0 24px 24px 24px;
      display: grid;
      justify-content: center;
      align-items: end;
    }
  }

  @media(max-width: $sm) {
    .testimonial {
      grid-area: testimonial;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: start;
      min-height: 80%;
    }

    .buttonArea {
      display: flex;
      flex-direction: column;
      grid-area: button;
      width: 100%;
      height: 100%;
    }
    .gridSection {
      padding: 24px;
    }
  }

  .bounceDiv {
    grid-area: arrow;
    display: flex;
  }

  .bounce {
    animation-name: bounce;
    animation-timing-function: ease;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(0);
    }
    64% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}