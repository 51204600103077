@import '../../variables';

.contact-form {
  width: 100%;
}

.maps {
  margin-top: 40px;
  width: 100%;
  height: 40vh;
  border: 0;
}



