@import '../../variables';

@font-face {
  font-family: "LibreFranklin PDF";
  src: url("../../assets/fonts/LibreFranklin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto PDF";
  src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@media screen {
  .print-only {
    display: none !important;
  }
}

#single-course-card-container {
  .overlay-text-container {
    position: relative;
  }

  #print-button {
    position: absolute;
    top: 40px;
    right: 0;
    padding: 10px 20px;
    text-align: center;
    background-color: $primary-main;
    color: white;
  }

  .course-image {
    display: block;
    margin: 40px 0;
    width: 120px;
  }

  .course-detail-button {
    display: block;
    color: white;
    background-color: $primary-main;
    padding: 10px 15px;
    width: 100%;
    margin: 40px auto 0;
  }

  svg {
    vertical-align: middle;
    padding-bottom: 5px;
  }

  .k-pdf-export, kendo-pdf-document, .k-grid {
    * {
      font-family: "LibreFranklin PDF", sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: "Roboto PDF", sans-serif;
    }

    h3 {
      font-size: 16pt;
    }

    h4 {
      font-size: 14pt;
    }
  }
}

@media (min-width: $md) {
  #single-course-card-container {
    .course-detail-button {
      width: 300px;
    }
  }
}

@media print {
  .header,
  .upper-footer,
  .lower-footer,
  .contact-button {
    display: none;
  }

  .course-image {
    display: block;
    width: 2cm !important;
    margin: 0 !important;
  }

  .logo {
    position: fixed;
    top: 0;
    right: 0;
    width: 2.5cm;
  }

  .footer-address {
    position: fixed;
    bottom: 0;
    right: 0;
    font-weight: bold;
    font-size: 11pt;
    line-height: 13pt;
    color: black;
    font-style: normal !important;
    text-align: right;

    a {
      color: black;
    }
  }

  .page-only {
    display: none !important;
  }

  h3 {
    font-size: 16pt;
    margin: 0.4cm 0 0.05cm 0;
    padding: 0;
  }

  h4 {
    font-size: 14pt;
    margin: 0.2cm 0 0.02cm 0;
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  p, li, div, span, ::marker {
    font-size: 12pt;
  }

  @page {
    margin: 2cm;
    border: 2cm solid white;
  }

  .paper-background {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
