@import "variables.scss";

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap');

body {
  background-color: white;
}

main {
  min-height: calc(100vh - 448px);
  background-color: $secondary-50;
}

.paper-testimonial {
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px;
  background-color: $primary-main;
  border-radius: 8px;
}

.paper-background {
  background-color: white;
  margin: 40px 0;
  padding: 40px 80px 80px;
  border-radius: 8px;
}

@media (max-width: $lg) {
  .paper-background {
    padding: 40px 40px 40px;
  }
}

@media (max-width: $md) {
  .paper-background {
    margin: 30px 0;
    padding: 30px 30px 30px;
  }
}

// Fonts
p, button, li {
  font-family: "Libre Franklin", sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 96px;
  line-height: 112px;
  font-weight: 300;
}

h2 {
  font-size: 60px;
  line-height: 72px;
  font-weight: 300;
  padding: 40px 0 30px;
  margin: 0;
}

h3 {
  font-size: 42px;
  line-height: 56px;
  font-weight: 400;
  padding: 40px 0 30px;
  margin: 0;
}

h4 {
  font-size: 34px;
  line-height: 36px;
  font-weight: 400;
}

.subtitle1 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.subtitle2 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.body1 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

main h2 {

}

@media (min-width: $xl) {
  h5 {
    font-size: 32px;
    font-weight: 400;
    margin: 0;
  }

  h6 {
    font-size: 26px;
    font-weight: 400;
    margin: 0;
  }
}

@media (min-width: $lg) {
  h5 {
    font-size: 27px;
    font-weight: 400;
    margin: 0;
  }

  h6 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
  }
}


@media (min-width: $md) {
  h5 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
  }

  h6 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
}

@media (min-width: $sm) and (max-width: $md) {
  h5 {
    font-size: 19px;
    font-weight: 400;
    margin: 0;
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
  }
}

@media (max-width: $sm) {
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
  }

  h6 {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
}


/*
  Layout Containers
 */

.fullwidth-container {
  width: 100%;
}

.limitwidth-container {
  width: 100%;
  padding: 2px 24px;
  margin: auto;
}

@media (min-width: $md) {
  .limitwidth-container {
    padding: 40px 80px;
  }
}

@media (min-width: $lg) {
  .limitwidth-container {
    padding: 40px 130px;
  }
}

@media (min-width: $lg) {
  .limitwidth-container {
    max-width: 2200px;
    padding: 40px 180px;
  }
}

.shopping-cart-snackbar {
  p {
    font-weight: normal;
  }

  p {
    color: black;
  }

  a {
    display: block;
    width: 100%;
    color: $primary-dark;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
  }
}

/*
  compucollege images
*/

.compucollege-image {
  width: 100%;
  margin-bottom: 40px;
  height: auto;
  border-radius: 2px;
  top: auto !important;
  bottom: calc(0 + 100%) !important;
}


/*
  Cookie Consent
 */
.react-cookie-law-dialog {
  padding: 15px 20px !important;
  top: auto !important;
  bottom: 0 !important;
  background-color: $secondary-light !important;

  .react-cookie-law-container::before {
    content: "Verwendung von Cookies";
    font-size: 1.3em;
    font-weight: bold;
  }

  .react-cookie-law-button-wrapper button {
    border: none;
    padding: 5px 7px !important;
    margin-bottom: 12px !important;
    margin-top: 6px;
    background-color: $primary-dark !important;
  }
}