@import "../../variables";

.contact-form-address-container {
  width: 100%;
  margin-bottom: 40px;

  .link-text {
    a {
      color: black;
    }

    a:hover {
      color: $primary-light;
    }
  }

  .iconLine {
    margin-left: 30px;
    display: flex;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-top: 4px;
    margin-left: -26px;
  }
}





