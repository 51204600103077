@import "../../variables";


.lower-footer-container {
  width: 100%;
  padding: 25px 0;
}



