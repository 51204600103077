@import "../../../variables";

  .testimonial-text {
    text-align: center;
  }

.testimonial-author {
  text-align: center;
}





