@import '../../variables';

#checkoutPage {
  position: relative;

  .image-card {
    width: 100%;
    height: auto;
    margin: auto auto 30px;
  }

  .media-card {
    float: left;
  }

  .course-text {
    padding: 10px 30px;
    width: 80%;
    display: flex;
    flex-direction: row;
  }

  .item-title-description {
    width: 80%;
    margin-top: 34px;
  }

  .card-action-container {
    flex-direction: row;
    display: flex;
  }

  .item-quantity {
    flex-direction: row;
    display: flex;
  }

  .additional-user-actions {
    margin-top: 16px;

    button {
      width: 200px;
    }
  }

  @media screen and (max-width: 1000px) {
    .card-action-container {
      flex-direction: column;
      display: flex;
    }

    .course-text {
      flex-direction: column;
      display: flex;
      width: 100%;
    }

    img {
      width: 100% !important;
    }

    .item-price-quantity {
      width: 100%;
    }

    .item-title-description {
      width: 100%;
    }
  }

  .course-title {
    margin-bottom: 10px;
    margin-top: -15px;
  }

  .course-short-description {
    overflow: hidden;
  }


  img {
    padding: 15px;
    margin: auto;
    object-fit: contain;
    width: 20%;
  }

  .item-price-quantity {
    flex-direction: row;
    align-items: center;
    display: flex;
    height: 100%;
    padding: 10px 0px 18px 0px;
  }

  .course-detail-button {
    display: block;
    color: $secondary-light;
    background-color: $primary-main;
    padding: 10px 15px;
    margin: 20px auto;
  }

  .course-remove-button {
    display: block;
    color: $secondary-light;
    background-color: indianred;
    padding: 10px 15px;
    margin: 20px auto;
  }
}