@import "../../../variables";

@media (min-width: $md) {
  .icon-book-reader {
    grid-area: icon-book-reader;
    justify-self: end;
    width: 125px;
    height: 125px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/bookreader_icon.svg');
  }

  .icon-graduate {
    grid-area: icon-graduate;
    width: 125px;
    height: 125px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/school_icon.svg');
  }
  .grid-container {
    width: 100%;
    grid-template-areas: "text1 text1 icon-book-reader"
                      "icon-graduate text2 text2";
    display: grid;
    column-gap: 5%;
    row-gap: 100px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: $md) {
  .icon-book-reader {
    grid-area: icon-book-reader;
    justify-self: end;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/bookreader_icon.svg');
  }

  .icon-graduate {
    grid-area: icon-graduate;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/school_icon.svg');
  }
  .grid-container {
    width: 100%;
    grid-template-areas: "text1 text1 icon-book-reader"
                      "icon-graduate text2 text2";
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2% 5%;
    display: grid;
    column-gap: 2%;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
  }
}


.text1 {
  grid-area: text1;
}

.text2 {
  grid-area: text2;
}



