@import "../../variables";

a {
  text-decoration: none;
}

.header {
  padding-top: 30px;
  padding-bottom: 30px;

  button {
    color: $secondary-900;
    text-transform: none;
    font-size: 18px;
    padding-bottom: 3px;
  }

  button:hover, a.active button {
    border-bottom: 5px solid $primary-dark;
    background-color: white;
    border-radius: 0;

    span {
      color: $primary-dark;
    }
  }

  .menu-button:hover {
    border: none;
  }
}

.button {
  margin-right: 16px;
  white-space: nowrap;
}

.menu-button {
  align-content: center;
  align-self: center;
  padding: 2px 2px 2px 2px;
}

.section-desktop {
  display: none;
}

.section-mobile {
  display: flex;
}

#menu-appbar {
  a li {
    color: $secondary-900;
  }

  a li:hover {
    background-color: $primary-dark;
    color: white;
  }

  a.active li {
    background-color: $primary-dark;
    color: white;
  }
}

@media (max-width: $md) {
  header {
    div:first-child {
      display: block;
    }

    a {
      display: block;
    }
  }

  .image {
    display: block;
    width: 80px;
    margin: auto;
  }
  .toolbar {
    margin: 4px;
  }
  .right-div {
    position: absolute;
    right: 0;
    bottom: 75px;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  .shopping-cart-mobile-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media (min-width: $md) {
  .right-div {
    display: flex;
    margin-left: auto;
    height: 50px;
  }

  .toolbar {
    padding: 0 0;
  }

  .image {
    vertical-align: middle;
    max-width: 80px;
    padding: 10px 0;
    margin-right: 50px;
  }

  .section-desktop {
    display: flex;

    a button {
      margin-left: 35px;
      margin-right: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .section-mobile {
    display: none;
  }
}