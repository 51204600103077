@import "../../variables";

.icon-bar {
  position: relative;
  font-size: 100px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  a {
    margin: 10px 40px;
    display: inline-block;
    height: 50px;
    width: 50px;
  }

  svg {
    fill: white;
    display: inherit;
  }

  svg:hover {
    fill: $primary-dark;
  }
}

#footer-triangle {
  position: absolute;
  right: calc(-20vw + 3px);
  bottom: 0;

  width: 0;
  height: 0;

  border-top: 70px solid transparent;
  border-left: 160px solid transparent;
  border-bottom: 70px solid $secondary-400;
}

@media(max-width: $lg) {
  #footer-triangle {
    display: none;
  }
}

.social-media-icons {
  font-size: 50px;
}

@media(max-width: $md) {
  .icon-bar {
    a {
      margin: 10px 20px;
    }
  }
}