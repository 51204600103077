@import "../../../variables";

.post-icon {
  margin: auto;
  grid-area: icon-book-reader;
  justify-self: end;
  width: 150px;
  height: 150px;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}


@media (max-width: $md) {
  .post-icon {
    width: 80%;
    height: 80%;
  }
}