@import '../../variables';

#coursePage {
  position: relative;

  .image-card {
    width: auto;
    height: auto;
    margin: auto auto 30px;
  }

  .course-text {
    padding: 15px;
  }

  .course-title {
    font-size: 1.3em;
    margin-bottom: 10px;
    margin-top: -15px;
    height: 48px;
    color: black;
  }

  .course-short-description {
    height: 145px;
    overflow: hidden;
  }

  img {
    padding: 35px;
    margin: auto;
    object-fit: contain;
  }

  .course-detail-button {
    display: block;
    color: white;
    background-color: $primary-main;
    padding: 10px 15px;
    margin: 20px auto;
  }

  .course-purchase-button {
    height: 44px;
  }

  .course-card-buttons button:hover {
    background-color: $primary-dark;
  }
}