// Colors
$primary-light: #4b69bd;
$primary-main: #043f8c;
$primary-dark: #001a5e;
$secondary-light: #e6ecf2;
$secondary-main: #b4babf;
$secondary-dark: #848a8f;
$secondary-300: #949FA8;
$secondary-400: #7D8A96;
$secondary-900: #282D32;
$secondary-50: #EEEEEE;

// Breakpoints
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

